::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #aaaaaa7c;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #567af1;
}

.scroll-container {
    scrollbar-width: thin;
    scrollbar-color: #afafaf49 #f1f1f1;
}
